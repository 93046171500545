<script setup lang="ts">
import type {
  IManagerFieldEmits,
  IManagerFieldProps,
} from '@manager/components/Field/types'
import { useFieldCache, useFieldCalculation, useFieldCast } from '@manager'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
})
const emit = defineEmits<IManagerFieldEmits>()

const parentValue = useVModel(props, 'parentValue', emit)
const { cast, castPlugin } = useFieldCast(props.node)

// Cast value and fallback to default
parentValue.value[props.node.name] = cast(
  parentValue.value[props.node.name],
  props.node.defaultValue,
)

// Initialize calculated value, after setting default value
const { setCalculated } = useFieldCalculation(props.node, parentValue)

useFieldCache(props.node, parentValue)
</script>

<template>
  <ManagerField :node="node">
    <template
      #default="{ id, name, type, label, validation, validationMessages }"
    >
      <FormKit
        :id="id"
        v-model="parentValue[name]"
        type="number-increment"
        :name="name"
        :label="label"
        label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
        :plugins="[castPlugin]"
        :validation="validation"
        :validation-messages="validationMessages"
        validation-visibility="live"
        :min="node.min ?? undefined"
        :max="node.max ?? undefined"
        @input="setCalculated"
      />
    </template>
  </ManagerField>
</template>
